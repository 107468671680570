import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const PhotographyPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  console.log(data)

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Photography"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Photography</h2>
          <p>
            I started pursuing photography as an art form when I was 14.
            Photography is a form of meditation for me. It is a medium for
            taking the world in as it is, to just look at it, and feel.
          </p>

          <div>
            {data.allFile.edges.map(edge => {
              if (edge && edge.node && edge.node.childImageSharp)
                return (
                  <figure className="kg-card kg-image-card kg-width-wide">
                    <Img
                      fluid={edge.node.childImageSharp.fluid}
                      className="kg-image"
                    />
                  </figure>
                )
            })}
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { relativeDirectory: { eq: "photography" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <PhotographyPage location={props.location} data={data} {...props} />
    )}
  />
)
